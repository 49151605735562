<template>

  <img loading="lazy" 
    :class="['img-premium', `size-${props.size}`]" 
    src="/images/label-premium.svg"
    alt="Premium account"
    title="Premium account"
    />

</template>


<script setup lang="ts">
 
interface PageProps {
  size?: string
}

const props = withDefaults(defineProps<PageProps>(), {
  size: 'medium'
})


</script>
 

 
<style scoped>
 
.img-premium{
  width: 20px;
  aspect-ratio: 1/1;
}

.img-premium.size-small{
  width: 10px;
}

.img-premium.size-medium{
  width: 20px;
}

.img-premium.size-big{
  width: 30px;
}

</style>